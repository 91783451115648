.pool-label{
    margin: 40px 10px;
    color: white;
    font-size: larger;
    font-weight: bold;
}
.chart-div{
    margin: 7px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 50px 50px; */
}   
.apexcharts-legend-text{
    color: white !important;
}
.apexcharts-text .apexcharts-yaxis-label tspan title{
    color: white !important;
}